import { useQuery, useQueryClient, useMutation } from "react-query";
import { fetchAccounts, addAccount, editAccount, deleteAccount, eraseAccount, fetchBackups, restoreBackup } from "../helpers/api";
import { checkUserAuthorizedQuery } from 'helpers/current-user';

export const useAccounts = () => {
  const query = useQuery(["get-accounts"], fetchAccounts, {
    select: (data) => data,
    //retry: 2,
    //retryDelay: 2000,
  });
  checkUserAuthorizedQuery(query);
  return query;
};

export const useAddAccount = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-account"], {
    mutationFn: (data) => addAccount(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-accounts'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-account"]);
};

export const useEditAccount = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-account"], {
    mutationFn: (data) => editAccount(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-accounts'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-account"]);
};

export const useDeleteAccount = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-account"], {
    mutationFn: (data) => deleteAccount(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-accounts'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-account"]);
};

export const useEraseAccount = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["erase-account"], {
    mutationFn: (data) => eraseAccount(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-accounts'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["erase-account"]);
};

export const useBackup = (accountId) => {
  return useQuery(["get-backup", accountId], fetchBackups, {
    select: (data) => data,
  });
};

export const useRestoreAccount = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["restore-account"], {
    mutationFn: (data) => restoreBackup(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-accounts'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["restore-account"]);
};