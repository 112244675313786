import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { history } from 'helpers';
import { PrivateRoute, VerifiedRoute } from 'components';
import { Navigation } from 'sections';
import { Home } from 'pages/home';
import { Login } from 'pages/login';
import { Users } from 'pages/users';
import { Verify } from 'pages/verify';
import { Profile } from 'pages/profile';
import { Locations } from 'pages/locations';
import { Accounts } from 'pages/accounts';
import { Budget } from 'pages/budget';
import { Boards } from 'pages/boards';
import { Vaults } from 'pages/vaults';
import { Network } from 'pages/network';
import { News } from 'pages/news';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BoardViewer } from 'pages/boards/BoardViewer';
import { StatusBar } from 'sections/StatusBar';
import { Inventories } from 'pages/inventories/Inventories';

export { App };

function App() {
    // init custom history object to allow navigation from 
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <div className="app-container">
            <Navigation />
            <StatusBar/>
            <Routes>
                <Route path="/" element={<PrivateRoute><VerifiedRoute><Home /></VerifiedRoute></PrivateRoute>}/>
                <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>}/>
                <Route path="/locations" element={<PrivateRoute><Locations /></PrivateRoute>}/>
                <Route path="/accounts" element={<PrivateRoute><VerifiedRoute><Accounts /></VerifiedRoute></PrivateRoute>}/>
                <Route path="/budget" element={<PrivateRoute><VerifiedRoute><Budget /></VerifiedRoute></PrivateRoute>}/>
                <Route path="/boards" element={<PrivateRoute><VerifiedRoute><Boards /></VerifiedRoute></PrivateRoute>}/>
                <Route path="/vaults" element={<PrivateRoute><VerifiedRoute><Vaults /></VerifiedRoute></PrivateRoute>}/>
                <Route path="/network" element={<PrivateRoute><VerifiedRoute><Network /></VerifiedRoute></PrivateRoute>}/>
                <Route path="/news" element={<PrivateRoute><VerifiedRoute><News /></VerifiedRoute></PrivateRoute>}/>
                <Route path="/inventory" element={<PrivateRoute><VerifiedRoute><Inventories /></VerifiedRoute></PrivateRoute>}/>
                <Route path="/users" element={<PrivateRoute><VerifiedRoute><Users /></VerifiedRoute></PrivateRoute>}/>
                <Route path="/verify" element={<PrivateRoute><Verify /></PrivateRoute>}/>
                <Route path="/login" element={<Login />} />
                <Route path="/view/:id" element={<BoardViewer />} />
                <Route path="/view/:id/:id2" element={<BoardViewer />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </div>
    );
}
