import apiClient from "helpers/axios-create";

export const fetchAccountTypes = async () => {
  return apiClient.get("/accounttype").then((res) => res.data);
};

export const fetchBanks = async () => {
  return apiClient.get("/bank").then((res) => res.data);
};

export const fetchAccounts = async () => {
  return apiClient.get("/account").then((res) => res.data);
};

export const addAccount = async (data) => {
  const { name, bankId, accountTypeId } = data;
  const body = { name, bankId, accountTypeId };
  return apiClient({
    url: "/account",
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const editAccount = async (data) => {
  const { id, name, bankId, accountTypeId } = data;
  const body = {id, name, bankId, accountTypeId };
  return apiClient({
    url: `/account/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const deleteAccount = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/account/${id}`,
    method: "DELETE",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};

export const eraseAccount = async (data) => {
  const { accountId } = data;
  return apiClient({
    url: `/transaction/all/${accountId}`,
    method: "DELETE",
    data: accountId,
  }).then(({ data }) => {
    return data;
  });
};

export const fetchTransactionPayees = async (data) => {
  return apiClient.get(`/transaction/payees/${data.queryKey[1]}`).then((res) => res.data);
};
export const fetchTransactionCategories = async (data) => {
  return apiClient.get(`/transaction/categories/${data.queryKey[1]}`).then((res) => res.data);
};
export const fetchLatestTransactionOfPayee = async (data) => {
  return apiClient.get(`/transaction/getLatestTransactionOfPayee/${data.queryKey[1]}/${data.queryKey[2]}`).then((res) => res.data);
};
export const fetchTransactions = async (data) => {
  if (data.pageParam === undefined) {
    data.pageParam = 1;
  }
  return apiClient.get(`/transaction/${data.queryKey[1]}/${data.queryKey[2]}/${data.pageParam}/${data.queryKey[4]}/${data.queryKey[5]}/${data.queryKey[6]}`).then((res) => res.data);
};

export const fetchFilteredBalance = async (data) => {
  return apiClient.get(`/transaction/getFilteredBalance/${data.queryKey[1]}/${data.queryKey[2]}/${data.queryKey[3]}`).then((res) => res.data);
};

export const addTransaction = async (data) => {
  const { accountId, category, amount, payee, date } = data;
  const body = { accountId, category, amount, payee, date };
  return apiClient({
    url: "/transaction",
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const importTransactions = async (data) => {
  const { accountId, fileData } = data;
  const body = { accountId, fileData };
  return apiClient({
    url: `/transaction/import`,
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const editTransaction = async (data) => {
  const { id, accountId, category, amount, payee, date } = data;
  const body = {id, accountId, category, amount, payee, date };
  return apiClient({
    url: `/transaction/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const deleteTransaction = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/transaction/${id}`,
    method: "DELETE",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};

export const fetchUsers = async () => {
  return apiClient.get("/users").then((res) => res.data);
};

export const addUser = async (data) => {
  const { firstName, lastName, username, password } = data;
  const body = { firstName, lastName, username, password };
  return apiClient({
    url: "/users",
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const editUser = async (data) => {
  const { id, firstName, lastName, username, password, isActive } = data;
  const body = {id, firstName, lastName, username, password, isActive };
  return apiClient({
    url: `/users/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const editUserPassword = async (data) => {
  const { id, password } = data;
  const body = {id, password };
  return apiClient({
    url: `/users/password/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const editUserProfile = async (data) => {
  const { id, firstName, lastName } = data;
  const body = {id, firstName, lastName };
  return apiClient({
    url: `/users/profile/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const deactivateUser = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/users/deactivate/${id}`,
    method: "PUT",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};

export const deleteUser = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/users/${id}`,
    method: "DELETE",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};

export const fetchBackups = async (data) => {
  return apiClient.get(`/backup/${data.queryKey[1]}`).then((res) => res.data);
};

export const restoreBackup = async (data) => {
  const { id } = data;
  const body = {id };
  return apiClient({
    url: `/backup/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const fetchSummaryByYear = async () => {
  return apiClient.get("/transaction/getTransactionSummariesByYear").then((res) => res.data);
};
export const fetchSummaryByMonth = async (data) => {
  return apiClient.get(`/transaction/getTransactionSummariesByMonth/${data.queryKey[1]}`).then((res) => res.data);
};
export const fetchSummaryTransactions = async (data) => {
  return apiClient.get(`/transaction/${data.queryKey[1]}/${data.queryKey[2]}`).then((res) => res.data);
};
export const fetchSummaryByYearByAccount = async (data) => {
  return apiClient.get(`/transaction/getTransactionSummariesByYear/${data.queryKey[1]}`).then((res) => res.data);
};
export const fetchSummaryByMonthByAccount = async (data) => {
  return apiClient.get(`/transaction/getTransactionSummariesByMonth/${data.queryKey[1]}/${data.queryKey[2]}`).then((res) => res.data);
};
export const fetchSummaryTransactionsByAccount = async (data) => {
  return apiClient.get(`/transaction/${data.queryKey[1]}/${data.queryKey[2]}/${data.queryKey[3]}`).then((res) => res.data);
};

export const fetchBudgetExpenses = async () => {
  return apiClient.get(`/budget/expense`).then((res) => res.data);
};
export const fetchBudgetIncome = async () => {
  return apiClient.get(`/budget/income`).then((res) => res.data);
};
export const addBudget = async (data) => {
  const { budgetTypeId, name, amount } = data;
  const body = { budgetTypeId, name, amount };
  return apiClient({
    url: "/budget",
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const editBudget = async (data) => {
  const { id, budgetTypeId, name, amount } = data;
  const body = {id, budgetTypeId, name, amount };
  return apiClient({
    url: `/budget/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const deleteBudget = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/budget/${id}`,
    method: "DELETE",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};

export const fetchBudgetMortgages = async (data) => {
  return apiClient.get(`/budget/mortgage`).then((res) => res.data);
};
export const addBudgetMortgage = async (data) => {
  const {budgetId, name, houseCost, downPayment, paidToDate, rate, maintenance, propertyTax, timeFrame } = data;
  const body = { budgetId, name, houseCost, downPayment, paidToDate, rate, maintenance, propertyTax, timeFrame};
  return apiClient({
    url: "/budget/mortgage",
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const editBudgetMortgage = async (data) => {
  const { id, budgetId, name, houseCost, downPayment, paidToDate, rate, maintenance, propertyTax, timeFrame} = data;
  const body = {id, budgetId, name, houseCost, downPayment, paidToDate, rate, maintenance, propertyTax, timeFrame };
  return apiClient({
    url: `/budget/mortgage/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const deleteBudgetMortgage = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/budget/mortgage/${id}`,
    method: "DELETE",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};

export const fetchBoards = async (data) => {
  return apiClient.get(`/board`).then((res) => res.data);
};
export const addBoard = async (data) => {
  const {name} = data;
  const body = { name };
  return apiClient({
    url: "/board",
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const editBoard = async (data) => {
  const { id, name} = data;
  const body = {id, name };
  return apiClient({
    url: `/board/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const deleteBoard = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/board/${id}`,
    method: "DELETE",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};

export const fetchBoardLists = async (data) => {
  if (data.queryKey[1] === '0' || data.queryKey[1] === 0) { return false; }
  return apiClient.get(`/board/boardList/${data.queryKey[1]}`).then((res) => res.data);
};
export const addBoardList = async (data) => {
  const {boardId, name} = data;
  const body = { boardId, name };
  return apiClient({
    url: "/board/boardList",
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const editBoardList = async (data) => {
  const { id, boardId, name} = data;
  const body = {id, boardId, name };
  return apiClient({
    url: `/board/boardList/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const deleteBoardList = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/board/boardList/${id}`,
    method: "DELETE",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};

export const fetchBoardListCard = async (data) => {
  return apiClient.get(`/board/boardListCardDetail/${data.queryKey[1]}`).then((res) => res.data);
};
export const fetchBoardListCards = async (data) => {
  return apiClient.get(`/board/boardListCards/${data.queryKey[1]}`).then((res) => res.data);
};
export const addBoardListCard = async (data) => {
  const {id, boardListId, name, description, dateStart, dateEnd, timeStart, timeEnd, price, complete, notify, shareable, location, latitude, longitude, repeat, repeatRemind} = data;
  const body = { id, boardListId, name, description, dateStart, timeStart, timeEnd, dateEnd, price, complete, notify, shareable, location, latitude, longitude, repeat, repeatRemind };
  return apiClient({
    url: "/board/boardListCard",
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const editBoardListCard = async (data) => {
  const { id, boardListId, name, description, dateStart, dateEnd, timeStart, timeEnd, price, complete, notify, shareable, location, latitude, longitude, repeat, repeatRemind} = data;
  const body = {id, boardListId, name, description, dateStart, dateEnd, timeStart, timeEnd, price, complete, notify, shareable, location, latitude, longitude, repeat, repeatRemind };
  
  return apiClient({
    url: `/board/boardListCard/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const editBoardListCardNetworks = async (data) => {
  const { id, networkIds} = data;
  const body = networkIds;
  
  return apiClient({
    url: `/board/boardListCardNetworks/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const deleteBoardListCard = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/board/boardListCard/${id}`,
    method: "DELETE",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};

export const positionBoardList = async (data) => {
  const { id, boardId, position, step} = data;
  const body = {id, boardId, position, step };
  return apiClient({
    url: `/board/boardListPosition/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const positionBoardListCard = async (data) => {
  const { id, boardListId, position, step} = data;
  const body = {id, boardListId, position, step };
  return apiClient({
    url: `/board/boardListCardPosition/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const updateBoardListCardNetwork = async (data) => {
  const { id, statusId} = data;
  const body = {id, statusId };
  
  return apiClient({
    url: `/board/boardListCardNetworks/status/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const fetchScheduledBoardListCards = async () => {
  return apiClient.get(`/board/getScheduledBoardListCards`).then((res) => res.data);
};

export const fetchVerify = async (data) => {
  return apiClient.get(`/Users/verify/${data.queryKey[1]}`).then((res) => res.data);
};

export const editUserVerification = async (data) => {
  const {code} = data;
  const body = {code};
  return apiClient({
    url: `/Users/verify/${code}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};

export const fetchVaults = async (data) => {
  return apiClient.get(`/vault`).then((res) => res.data);
};
export const addVault = async (data) => {
  const {name} = data;
  const body = { name };
  return apiClient({
    url: "/vault",
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const editVault = async (data) => {
  const { id, name} = data;
  const body = {id, name };
  return apiClient({
    url: `/vault/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const deleteVault = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/vault/${id}`,
    method: "DELETE",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};

export const fetchVaultRecords = async (data) => {
  return apiClient.get(`/vault/vaultRecord/${data.queryKey[1]}`).then((res) => res.data);
};
export const fetchVaultRecordKey = async (data) => {
  return apiClient.get(`/vault/vaultRecord/key/${data.queryKey[1]}`).then((res) => res.data);
};
export const addVaultRecord = async (data) => {
  const {vaultId, name, username, password, notes} = data;
  const body = { vaultId, name, username, password, notes };
  return apiClient({
    url: "/vault/vaultRecord",
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const editVaultRecord = async (data) => {
  const { id, vaultId, name, username, password, notes} = data;
  const body = {id, vaultId, name, username, password, notes };
  return apiClient({
    url: `/vault/vaultRecord/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const deleteVaultRecord = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/vault/vaultRecord/${id}`,
    method: "DELETE",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};
export const uploadToVaultRecord = async (data) => {
  return apiClient({
    url: "/vault/vaultRecord/upload",
    method: "POST",
    data: data.formData,
    headers: {
      "Content-Type":"multipart/form-data"
    }
  }).then(({ data }) => {
    return data;
  });
};
export const fetchVaultRecordUpload = async (data) => {
  return apiClient.get(`/vault/vaultRecord/upload/${data.queryKey[1]}`).then((res) => res.data);
};
export const deleteVaultRecordUpload = async (data) => {
  const { id, filename} = data;
  return apiClient({
    url: `/vault/vaultRecord/upload/${id}`,
    method: "DELETE",
    data: filename,
  }).then(({ data }) => {
    return data;
  });
};

export const fetchNetworks = async (data) => {
  return apiClient.get(`/network`).then((res) => res.data);
};
export const fetchBoardListCardNetworks = async (data) => {
  if (data.queryKey[1] === undefined) { return 0; }
  return apiClient.get(`/network/${data.queryKey[1]}`).then((res) => res.data);
};
export const addDefaultNetwork = async (data) => {
  return apiClient({
    url: "/network/default",
    method: "POST",
    data: data,
  }).then(({ data }) => {
    return data;
  });
};
export const addNetwork = async (data) => {
  const { id, parentId, firstName, lastName, position, phone, email, team, location, latitude, longitude, description, facebook, instagram, birthDate, anniversary, unlist, galleryLink, videoLink } = data;
  const body = { id, parentId, firstName, lastName, position, phone, email, team, location, latitude, longitude, description, facebook, instagram, birthDate, anniversary, unlist, galleryLink, videoLink  };
  return apiClient({
    url: "/network",
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const editNetwork = async (data) => {
  const { id, parentId, firstName, lastName, position, phone, email, team, location, latitude, longitude, description, facebook, instagram, birthDate, anniversary, unlist, galleryLink, videoLink } = data;
  const body = {id, parentId, firstName, lastName, position, phone, email, team, location, latitude, longitude, description, facebook, instagram, birthDate, anniversary, unlist, galleryLink, videoLink  };
  return apiClient({
    url: `/network/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const deleteNetwork = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/network/${id}`,
    method: "DELETE",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};
export const uploadToNetwork = async (data) => {
  return apiClient({
    url: "/network/upload",
    method: "POST",
    data: data.formData,
    headers: {
      "Content-Type":"multipart/form-data"
    }
  }).then(({ data }) => {
    return data;
  });
};
export const fetchLocations = async (data) => {
  return apiClient.get(`/location`).then((res) => res.data);
};
export const editLocation = async (data) => {
  const { id, name } = data;
  const body = {id, name };
  return apiClient({
    url: `/location/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const uploadToLocation = async (data) => {
  return apiClient({
    url: "/location/upload",
    method: "POST",
    data: data.formData,
    headers: {
      "Content-Type":"multipart/form-data"
    }
  }).then(({ data }) => {
    return data;
  });
};
export const fetchNews = async (data) => {
  return apiClient.get(`/news/${data.queryKey[1]}/${data.queryKey[2]}`).then((res) => res.data);
};
export const addNews = async (data) => {
  const {name, tag, pinned} = data;
  const body = { name, tag, pinned };
  return apiClient({
    url: "/news",
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const editNews = async (data) => {
  const { id, name, tag, pinned} = data;
  const body = {id, name, tag, pinned };
  return apiClient({
    url: `/news/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const deleteNews = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/news/${id}`,
    method: "DELETE",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};

export const fetchNewsHighlights = async (data) => {
  return apiClient.get(`/news/highlights`).then((res) => res.data);
};

export const fetchInventories = async (data) => {
  return apiClient.get(`/inventory`).then((res) => res.data);
};
export const fetchWarningInventories = async (data) => {
  return apiClient.get(`/inventory/warnings`).then((res) => res.data);
};
export const addInventory = async (data) => {
  const {name} = data;
  const body = { name };
  return apiClient({
    url: "/inventory",
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const editInventory = async (data) => {
  const { id, name} = data;
  const body = {id, name };
  return apiClient({
    url: `/inventory/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const deleteInventory = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/inventory/${id}`,
    method: "DELETE",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};

export const fetchInventoryRecord = async (data) => {
  return apiClient.get(`/inventory/inventoryRecord/${data.queryKey[1]}/${data.queryKey[2]}`).then((res) => res.data);
};
export const fetchInventoryRecords = async (data) => {
  return apiClient.get(`/inventory/inventoryRecord/${data.queryKey[1]}`).then((res) => res.data);
};
export const addInventoryRecord = async (data) => {
  const {inventoryId, name, detail, min, max, count, longitude, latitude, address} = data;
  const body = { inventoryId, name, detail, min, max, count, longitude, latitude, address };
  return apiClient({
    url: "/inventory/inventoryRecord",
    method: "POST",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const editInventoryRecord = async (data) => {
  const { id, inventoryId, name, detail, min, max, count, longitude, latitude, address} = data;
  const body = {id, inventoryId, name, detail, min, max, count, longitude, latitude, address };
  return apiClient({
    url: `/inventory/inventoryRecord/${id}`,
    method: "PUT",
    data: body,
  }).then(({ data }) => {
    return data;
  });
};
export const deleteInventoryRecord = async (data) => {
  const { id } = data;
  return apiClient({
    url: `/inventory/inventoryRecord/${id}`,
    method: "DELETE",
    data: id,
  }).then(({ data }) => {
    return data;
  });
};